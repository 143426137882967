.embla {
  width: 100%;
  max-width: 100vw;
}

.embla-thumbs {
  --thumbs-slide-spacing: 1rem;
  --thumbs-slide-height: 220px;
}

.embla-thumbs__viewport {
  overflow: hidden;
  width: 100vw;
}

.embla-thumbs__container {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: var(--thumbs-slide-spacing);
  gap: 1rem;
}

.embla-thumbs__slide {
  flex: 0 0 auto;
  width: auto;
}

.embla-thumbs__slide__img {
  width: 300px;
  height: 180px;
  object-fit: cover;
}

@media (max-width: 1200px) {
  .embla-thumbs__container {
    gap: 1rem;
  }

  .embla-thumbs__slide__img {
    width: 260px;
    height: 160px;
  }
}

@media (max-width: 1100px) and (min-width: 768px) {
  .embla-thumbs__container {
    gap: 1rem;
  }

  .embla-thumbs__slide__img {
    width: 220px;
    height: 140px;
  }
}

@media (max-width: 900px) and (min-width: 760px) {
  .embla-thumbs__container {
    gap: 1rem;
  }

  .embla-thumbs__slide__img {
    width: 220px;
    height: 140px;
  }
}

@media (max-width: 860px) {
  .embla-thumbs__slide__img {
    width: 200px;
    height: 140px;
  }
}

@media (max-width: 480px) {
  .embla-thumbs__slide__img {
    width: 150px;
    height: 100px;
  }
}
